




















































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class Settings extends Vue {
  goToForm(form: string) {
    const currentParams = this.$router.currentRoute.params.userId
    const targetRoute = `/profile/${currentParams}/setting/${form}`
    if (this.$route.path !== targetRoute) {
      this.$router.push(targetRoute)
    }
  }

  select = { label: 'Account', path: 'account' }

  items = [
    { label: 'Account', path: 'account' },
    { label: 'Customize profile', path: 'customize-profile' },
  ]
}
